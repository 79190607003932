import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Text } from "rebass";
import { WalletMultiButton } from "@solana/wallet-adapter-ant-design";
import { Card, Button } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  shortenAddress,
  mintOneTokenPresale,
} from "../../hooks/candyMachine";
import { Divider } from "antd";
import { useUserBalance } from "../../hooks/useUserBalance";
import { toPublicKey } from "../../utils/utils";

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const MintButton = styled(Button)`
  background: #017b3c;
  cursor: click;
  color: white;
  width: 30rem;
  height: 5rem;
  font-size: 3em;
  padding: 6px 8px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  // border-radius: 4px;
  letter-spacing: 0.02857em;
  border: solid #20d800;
  border-width: 4px;

  &:disabled {
    background: rgb(0 0 0 / 40%);
  }

  &:focus,
  &:hover {
    color: #fff;
    border: solid white;
    border-width: 4px;
    background: rgb(0 216 69 / 80%);
  }
`;

export const PresaleView = (props: HomeProps) => {
  const tokenAccess = "F9LsvCRestXiDz45nb7iUrShWoYmk539963XJr4VngQ9";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [balance, setBalance] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  // console.log(balance);
  // const [itemsAvailable, setItemsAvailable] = useState(0);
  // const [itemsRedeemed, setItemsRedeemed] = useState(0);
  // const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();
  const tokenBalance = useUserBalance(tokenAccess);

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        // itemsAvailable,
        itemsRemaining,
        price,
        // itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      // setItemsAvailable(itemsAvailable);
      // setItemsRemaining(itemsRemaining);
      // setItemsRedeemed(itemsRedeemed);

      setPrice(price);
      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneTokenPresale(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury,
          toPublicKey(tokenAccess)
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "confirmed",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>
      <LazyLoadImage
        src={`/images/yeti.png`}
        className="image"
        alt="title"
        width="250px"
        style={{ marginLeft: "20px" }}
      />
      <Card>
        {!wallet ? (
          <>
            <Text fontSize={42} fontWeight={400}>
              Welcome to the Presale of SOLYETIS!
            </Text>
            <Text fontSize={20}>
              Reminder: you can mint only once for the presale and
            </Text>
            <Text fontSize={20}>
              you can still mint with the same wallet when the public sale
              launches
            </Text>
            <Divider />
            <Text fontSize={24} fontWeight={400}>
              CONNECT YOUR WHITELISTED WALLET
            </Text>
            <WalletMultiButton
              type="primary"
              style={{
                background: "#017B3C",
                cursor: "click",
                color: "white",
                width: "30rem",
                height: "5rem",
                fontSize: "3em",
                padding: "6px 8px",
                boxSizing: "border-box",
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: "0.02857em",
                border: "solid #20d800",
                borderWidth: "4px",
              }}
            />
          </>
        ) : tokenBalance.hasBalance ? (
          <>
            {wallet && (
              <Text fontSize={32}>
                Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
              </Text>
            )}
            {/* {wallet && (
              <Text fontSize={32}>
                Balance: {(balance || 0).toLocaleString()} SOL
              </Text>
            )} */}
            <br />
            <Text fontSize={32}>
              MINT PRICE: {price ? price / anchor.web3.LAMPORTS_PER_SOL : "--"}{" "}
              SOL
            </Text>
            <Divider />
            <MintContainer>
              <MintButton
                disabled={isSoldOut || isMinting || !isActive}
                onClick={onMint}
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : isActive ? (
                  isMinting ? (
                    <CircularProgress />
                  ) : (
                    "MINT"
                  )
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                )}
              </MintButton>
            </MintContainer>

            <Snackbar
              open={alertState.open}
              autoHideDuration={6000}
              onClose={() => setAlertState({ ...alertState, open: false })}
            >
              <Alert
                onClose={() => setAlertState({ ...alertState, open: false })}
                severity={alertState.severity}
              >
                {alertState.message}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <Text fontSize={24}>You must connect your whitelisted wallet</Text>
        )}
      </Card>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24}h {minutes}m {seconds}s
    </CounterText>
  );
};
