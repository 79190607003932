import React from "react";
import "./../../App.less";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { WalletModalProvider } from "@solana/wallet-adapter-ant-design";

import { AppBar } from "../AppBar";
import styled from "styled-components";
import { Footer } from "antd/lib/layout/layout";
import { Text } from "rebass";
import { LazyLoadImage } from "react-lazy-load-image-component";

const { Header, Content } = Layout;

const AppBarStyled = styled(Header)`
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0px;
  position: relative;
  padding: 1rem;
  z-index: 2;
  padding: 30px 8px;

  .ant-menu-horizontal {
    border-bottom-color: transparent;
    background-color: transparent;
    line-height: inherit;
    font-size: 16px;
    margin: 0 10px;

    .ant-menu-item {
      margin: 0 10px;
      color: lightgrey;
      height: 35px;
      line-height: 35px;
      border-width: 0px !important;
    }

    .ant-menu-item:hover {
      color: white;
      border-width: 0px !important;
    }

    .ant-menu-item-selected {
      font-weight: bold;
    }
  }
`;

const AppStyled = styled.div<{ background: string }>`
  position: relative;
  height: 100%;
  text-align: center;
  min-width: 100%;
  display: flex;
  flex-direction: column;

  background-color: white;
  background-image: url(${(props: any) => props.background});
  background-position: center center;
  background-size: cover;
`;

const LayoutStyled = styled(Layout)`
  background: rgba(0, 0, 0, 0.1);
`;

export const AppLayout = React.memo(({ children }) => {
  return (
    <WalletModalProvider>
      <AppStyled background={"/images/background.png"}>
        <LayoutStyled>
          <AppBarStyled>
            <Link to="/">
              <LazyLoadImage
                src={`/images/title.png`}
                className="image"
                alt="title"
                width="250px"
              />
            </Link>
            <AppBar />
          </AppBarStyled>
          <Content
            className="site-layout"
            style={{
              padding: "20px 50px",
              margin: "auto",
              maxWidth: "1000px",
              overflow: "hidden",
            }}
          >
            {children}
          </Content>
          <Footer>
            {/* <BuiltOnStyled /> */}
            <Text fontSize={26}>
              You do not need to refresh this page when the countdown ends.
            </Text>
          </Footer>
        </LayoutStyled>
      </AppStyled>
    </WalletModalProvider>
  );
});
