import { HashRouter, Route, Switch } from "react-router-dom";
import { useMemo } from "react";
import { WalletProvider } from "@solana/wallet-adapter-react";
import { AppLayout } from "./components/Layout";
import { createTheme, ThemeProvider } from "@material-ui/core";

import { PresaleView, SaleView } from "./views";
import {
  getPhantomWallet,
  getSolletWallet,
  getSolflareWallet,
} from "@solana/wallet-adapter-wallets";
import * as anchor from "@project-serum/anchor";
import { AccountsProvider } from "./contexts/accounts";

export const yetiAuthority = new anchor.web3.PublicKey(
  "yeT3ik5jX5RuK8JF8P3RRHrr9g6ox6RMWbRMh3r9FxQ"
);
const treasury = new anchor.web3.PublicKey(
  "4ZNYVD4xXVEdSSQHMgjAA4gYn5Y68x5xTJqeuqTCtgGF"
);
// PRESALE
const presaleConfig = new anchor.web3.PublicKey(
  "HhgB5v3DJjnXeK1vWNKcRBjSUEWZ3XvHNfSxXuQmEEJ"
);
const presaleCandyMachineId = new anchor.web3.PublicKey(
  "9xV2BNrqAjSn2aquLhy8c2pshHe2goJhydBjNu4qxxFg"
);
//SALE
const saleConfig = new anchor.web3.PublicKey(
  "68qCZDR7ZyiCLgbAYJ2sZV4Uj54oZcS6Bif8g8J5LNom"
);
const saleCandyMachineId = new anchor.web3.PublicKey(
  "59z6tNUxWg66jBcznvtAtuWR9TQWAjGLqPbmqSDvgx6"
);
const presaleStartDateSeed = Date.parse("13 Oct 2021 19:30:00 GMT"); //parseInt("1634067000", 10) * 1000;
const saleStartDateSeed = Date.parse("13 Oct 2021 21:30:00 GMT"); //parseInt("1634256800", 10) * 1000;

// export const RPC_MAINNET_BETA =
//   "https://blue-wild-dew.solana-mainnet.quiknode.pro/2ef5315bb481b0ebed97e5a05125e843e6e6d668/";
// export const RPC_DEVNET =
//   "https://muddy-old-glitter.solana-devnet.quiknode.pro/f7205a68ca22e09113786ba8e6877822eebaf6ab/";
const rpcHost =
  "https://snowy-patient-fire.solana-mainnet.quiknode.pro/c6cb9a61ed437c1d623fcb1147e2204390aa2c4e/"; //mainnet
// "https://muddy-old-glitter.solana-devnet.quiknode.pro/f7205a68ca22e09113786ba8e6877822eebaf6ab/"; //devnet
export const connection = new anchor.web3.Connection(rpcHost);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

export function Routes() {
  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet()],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <HashRouter basename={"/"}>
        <WalletProvider wallets={wallets} autoConnect>
          <AccountsProvider>
            <AppLayout>
              <Switch>
                <Route
                  exact
                  path="/sale"
                  component={() => (
                    <SaleView
                      candyMachineId={saleCandyMachineId}
                      config={saleConfig}
                      connection={connection}
                      startDate={saleStartDateSeed}
                      treasury={treasury}
                      txTimeout={txTimeout}
                    />
                  )}
                />
                <Route
                  exact
                  path="/presale"
                  component={() => (
                    <PresaleView
                      candyMachineId={presaleCandyMachineId}
                      config={presaleConfig}
                      connection={connection}
                      startDate={presaleStartDateSeed}
                      treasury={treasury}
                      txTimeout={txTimeout}
                    />
                  )}
                />
                <Route
                  exact
                  path="/"
                  component={() => (
                    <PresaleView
                      candyMachineId={presaleCandyMachineId}
                      config={presaleConfig}
                      connection={connection}
                      startDate={presaleStartDateSeed}
                      treasury={treasury}
                      txTimeout={txTimeout}
                    />
                  )}
                />
              </Switch>
            </AppLayout>
          </AccountsProvider>
        </WalletProvider>
      </HashRouter>
    </ThemeProvider>
  );
}
